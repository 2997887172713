@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Basier';
  src: url('fonts/basiercircle-regular-webfont.woff2') format('woff2'), url('fonts/basiercircle-regular-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Basier';
  src: url('fonts/basiercircle-semibold-webfont.woff2') format('woff2'), url('fonts/basiercircle-semibold-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Basier';
  src: url('fonts/basiercircle-bold-webfont.woff2') format('woff2'), url('fonts/basiercircle-bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

:root {
  --primaryLighter: #eee5fa;
  --primaryLight: #884AD3;
  --primary: #642CA9;
  --primaryDark: #4D2182;
  --primaryDarker: #301452;

  --raisinBlack: #231F20;

  --background: #f6f4f5;
  --backgroundDark: #f1eff0;
}

* {
  -webkit-tap-highlight-color: transparent;
  text-decoration: none;
  outline: none;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Basier', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
